/* Social */
.social { 
    font-family: rubik, sans-serif;
    opacity: 0.8;
    display: flex;
    justify-content: space-between;
    max-width: 105px;
}

.social button { 
    padding: 0;
    margin: 0;
}

.social__single i { 
    font-size: 0.7em;
    margin-right: 5px;
}

.social__single .social__single-label { 
    font-size: 1em;
}
