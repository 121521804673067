/* RIGHT SECTION */
.right-section { 
    /* margin-top: 1.8em; */
    font-family: rubik, sans-serif;
}

.right-section ul {
    margin-left: 20px;
}

.right-section ul li {
    margin-bottom: 20px;
}

.right-section ul li button {
    padding: 0;
    margin: 0;
}

.right-section ul li:last-child {
    margin-top: 60px;
}

.right-section i { 
    font-size: 1.2em;
    margin-right: 10px;
    opacity: 0.75;
}

.right-section .btn__new {
    text-align: left;
    padding: 20px;
    margin: 30px;
    margin-left: 0;
    width: 100%;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 31px 0 0 31px;
}

.right-section .btn__new i {
    font-size: 0.8em;
    opacity: 1;
}

.App.light-theme .right-section .btn__new {
     box-shadow: 0px 12px 40px #EBEBEB;
}

.App.dark-theme .right-section .btn__new {
     box-shadow: 0px 12px 40px #000000;
}

/* MEDIA QUERIES FOR MOBILE VIEW */
@media screen and (max-width: 920px) {
    .right-section ul {
        margin-left: 0;
    }

    .right-section .btn__new { 
        position: absolute;
        bottom: 0;
        right: 0;
        width: auto;
        padding: 25px;
        margin: 0;
        min-width: 240px;
    }

    .App.light-theme #main .right-section,
    .App.light-theme #main .right-section * { 
        background: var(--light-body-background);
    }

    .App.dark-theme #main .right-section,
    .App.dark-theme #main .right-section * { 
        background: var(--dark-body-background);
    }
}