.textarea {    
    width: 100%;
    border: none;
    margin: 30px 0;
    resize: none;
    height: calc(81vh);
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.3em;
}

.back { 
    font-weight: 700;
}

.back i { 
    margin-right: 15px;
}