/* Social */
.social { 
    font-family: rubik, sans-serif;
    opacity: 0.8;
    display: flex;
    justify-content: space-between;
    max-width: 105px;
}

.social button { 
    padding: 0;
    margin: 0;
}

.social__single i { 
    font-size: 0.7em;
    margin-right: 5px;
}

.social__single .social__single-label { 
    font-size: 1em;
}

.App.dark-theme .ReactModal__Overlay { 
    background: black;
}
.social-share a:first-child { 
    margin-left: 0;
}
/* RIGHT SECTION */
.right-section { 
    /* margin-top: 1.8em; */
    font-family: rubik, sans-serif;
}

.right-section ul {
    margin-left: 20px;
}

.right-section ul li {
    margin-bottom: 20px;
}

.right-section ul li button {
    padding: 0;
    margin: 0;
}

.right-section ul li:last-child {
    margin-top: 60px;
}

.right-section i { 
    font-size: 1.2em;
    margin-right: 10px;
    opacity: 0.75;
}

.right-section .btn__new {
    text-align: left;
    padding: 20px;
    margin: 30px;
    margin-left: 0;
    width: 100%;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 31px 0 0 31px;
}

.right-section .btn__new i {
    font-size: 0.8em;
    opacity: 1;
}

.App.light-theme .right-section .btn__new {
     box-shadow: 0px 12px 40px #EBEBEB;
}

.App.dark-theme .right-section .btn__new {
     box-shadow: 0px 12px 40px #000000;
}

/* MEDIA QUERIES FOR MOBILE VIEW */
@media screen and (max-width: 920px) {
    .right-section ul {
        margin-left: 0;
    }

    .right-section .btn__new { 
        position: absolute;
        bottom: 0;
        right: 0;
        width: auto;
        padding: 25px;
        margin: 0;
        min-width: 240px;
    }

    .App.light-theme #main .right-section,
    .App.light-theme #main .right-section * { 
        background: var(--light-body-background);
    }

    .App.dark-theme #main .right-section,
    .App.dark-theme #main .right-section * { 
        background: var(--dark-body-background);
    }
}
.textarea {    
    width: 100%;
    border: none;
    margin: 30px 0;
    resize: none;
    height: calc(81vh);
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.3em;
}

.back { 
    font-weight: 700;
}

.back i { 
    margin-right: 15px;
}
:root {
    --light-body-background: #FAFCFF;
    --light-background: #FFFFFF;
    --light-text: #243552;
    --dark-body-background: #20202A;
    --dark-background: #23232D;
    --dark-text: #FFFFFF;
}

p {
    font-size: 1.35em;
    line-height: 1.25em;
    font-weight: 200;
    margin: 0.7em 0 0.92em 0;
}

.container { 
    max-width: 920px;
    margin: 0 auto;
}

.center * { 
    text-align: center;
}

/* APP */
.App { 
    padding: 3em 0;
    min-height: calc(100vh - 6em);
}

.App.light-theme * { 
    color: #243552; 
    color: var(--light-text);
}

.App.light-theme {
    background: #FAFCFF;
    background: var(--light-body-background);
}

.App.dark-theme * { 
    color: #FFFFFF; 
    color: var(--dark-text);
}

.App.dark-theme { 
    background: #20202A; 
    background: var(--dark-body-background);
}

.container.light-theme * { 
    background: #FFFFFF; 
    background: var(--light-background);
}

.container.dark-theme * { 
    background: #23232D; 
    background: var(--dark-background);
}

.App #main { 
    padding: 30px;
    padding-right: 0;
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 2fr 1fr;
    min-height: 88vh;
}

/* Thought Container */
.thought-container { 
    padding: 1.6em 0;
}

.App.light-theme #main .thought-container { 
    border-bottom: 1px dashed rgba(36, 53, 82, 0.12);
}

.App.dark-theme #main .thought-container { 
    border-bottom: 1px dashed rgba(255, 255, 255, 0.12);
}

.App.dark-theme #main .thought-container:last-child, 
.App.light-theme #main .thought-container:last-child { 
    border-bottom: none;
}

.date { 
    margin: 0;
    font-size: 0.7em;
    opacity: 0.48;
    text-transform: uppercase;
}


/* MEDIA QUERIES FOR MOBILE VIEW */
@media screen and (max-width: 920px) {
    .App { 
        padding: 0;
        min-height: 100vh;
    }
    
    .App #main { 
        display: block;
        margin-bottom: 45px;
        padding: 30px;
    }
    
    .App #main .right-section { 
        position: fixed;
        bottom: 0;
        left: 0;
        background: #fff;
        padding: 20px 30px;
        width: 100%;
    }

    .App #main .right-section ul li { 
        display: none;
    }

    .App #main .right-section ul li#toggle,
    .App #main .right-section ul li#account {
        display: inline;
        margin-right: 30px;
    }

    .App #main .right-section ul li i { 
        font-size: 1.2em;
        display: inline;
    }

    .App #main .right-section .nav__description {
        display: none;
    }

    p {
        font-size: 1.1em;
        line-height: 1.2em;
    }
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html {
    scroll-behavior: smooth;
}

html * {
    font-family: mr-eaves-modern, sans-serif;
}


html, body, div, span, applet, object, iframe, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

button { 
    border: none;
}

a {
    text-decoration: none;
}

a, a:active, a:focus {
    outline: 0 !important;
}

input::-moz-focus-inner {
    border: 0;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

button:focus {outline:0;}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}


::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

