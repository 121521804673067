:root {
    --light-body-background: #FAFCFF;
    --light-background: #FFFFFF;
    --light-text: #243552;
    --dark-body-background: #20202A;
    --dark-background: #23232D;
    --dark-text: #FFFFFF;
}

p {
    font-size: 1.35em;
    line-height: 1.25em;
    font-weight: 200;
    margin: 0.7em 0 0.92em 0;
}

.container { 
    max-width: 920px;
    margin: 0 auto;
}

.center * { 
    text-align: center;
}

/* APP */
.App { 
    padding: 3em 0;
    min-height: calc(100vh - 6em);
}

.App.light-theme * { 
    color: var(--light-text);
}

.App.light-theme {
    background: var(--light-body-background);
}

.App.dark-theme * { 
    color: var(--dark-text);
}

.App.dark-theme { 
    background: var(--dark-body-background);
}

.container.light-theme * { 
    background: var(--light-background);
}

.container.dark-theme * { 
    background: var(--dark-background);
}

.App #main { 
    padding: 30px;
    padding-right: 0;
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 2fr 1fr;
    min-height: 88vh;
}

/* Thought Container */
.thought-container { 
    padding: 1.6em 0;
}

.App.light-theme #main .thought-container { 
    border-bottom: 1px dashed rgba(36, 53, 82, 0.12);
}

.App.dark-theme #main .thought-container { 
    border-bottom: 1px dashed rgba(255, 255, 255, 0.12);
}

.App.dark-theme #main .thought-container:last-child, 
.App.light-theme #main .thought-container:last-child { 
    border-bottom: none;
}

.date { 
    margin: 0;
    font-size: 0.7em;
    opacity: 0.48;
    text-transform: uppercase;
}


/* MEDIA QUERIES FOR MOBILE VIEW */
@media screen and (max-width: 920px) {
    .App { 
        padding: 0;
        min-height: 100vh;
    }
    
    .App #main { 
        display: block;
        margin-bottom: 45px;
        padding: 30px;
    }
    
    .App #main .right-section { 
        position: fixed;
        bottom: 0;
        left: 0;
        background: #fff;
        padding: 20px 30px;
        width: 100%;
    }

    .App #main .right-section ul li { 
        display: none;
    }

    .App #main .right-section ul li#toggle,
    .App #main .right-section ul li#account {
        display: inline;
        margin-right: 30px;
    }

    .App #main .right-section ul li i { 
        font-size: 1.2em;
        display: inline;
    }

    .App #main .right-section .nav__description {
        display: none;
    }

    p {
        font-size: 1.1em;
        line-height: 1.2em;
    }
}